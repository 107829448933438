import React from 'react';
import { Navbar, Nav, Container, Button, Row, Col, Card, Dropdown } from 'react-bootstrap';
import { BodyText, ColumnsGap, PersonPlus, ChatRight } from 'react-bootstrap-icons';
import './LandingPage.css';
import { Link } from 'react-router-dom';
// import logo from '../../assets/logoNew.png';

const products = [
  {
    title: 'Transportation',
    description: 'Ensure safety and compliance, improve communication, while monitoring resource utilization.',
    path: '/user-guide',
  },
  {
    title: 'Industrial Intelligence',
    description: 'Monitor process, equipment, identify bottleneck areas and reduce operational cost with advanced analytics.',
    path: '/industrial',
  },
  {
    title: 'Unmanned Aerial Systems',
    description: 'Compute hardware/software engine that ingests data, autonomously controls and navigates aerial systems.',
    // path: '/products/unmanned-aerial-systems',
  },
  {
    title: 'Unmanned Ground Systems',
    description: 'Compute hardware and visualization platform for ingesting data and autonomously controling ground vehicles.',
    // path: '/products/unmanned-ground-systems',
  },
];

const LandingPage = () => {
  return (
    <>
      <Navbar expand="lg" className="top-bar" defaulExpanded={true}>
        <Container fluid>
          <Navbar.Brand href='/' className="logo">
            {/* <PatchCheck className="me-2" />  */}
            {/* <img src={logo} alt="Company Logo" className="logo-img" /> */}
            Data, Intelligence & Machines
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggle-custom" ms-auto>
            <ChevronExpand size={23} />
          </Navbar.Toggle> */}
          
          {/* <Navbar.Collapse id="basic-navbar-nav"> */}
            <Nav className="ms-auto">
              
              <Dropdown className="nav-item">
                <Dropdown.Toggle as={Nav.Link} id="products-dropdown" className="d-flex align-items-center">
                  <ColumnsGap className="me-2" /> Platforms
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to='/lph' className="d-flex align-items-center">
                    {/* <BusFront className="me-2" />  */}
                    School Transportation.
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to='/industrial' className="d-flex align-items-center">
                    {/* <GpuCard className="me-2" />  */}
                    Industrial Intelligence
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to='/' className="d-flex align-items-center">
                    {/* <AirplaneEngines className="me-2" />  */}
                    Autonomous Systems
                  </Dropdown.Item>
                  {/* <Dropdown.Item as={Link} to='/' className="d-flex align-items-center"> */}
                    {/* <Robot className="me-2" />  */}
                    {/* Unmanned Ground Systems (Engine) */}
                  {/* </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="nav-item">
                {/* <Dropdown.Toggle as={Nav.Link} id="guides-dropdown" className="d-flex align-items-center">
                  <ViewList className="me-2" /> Product Manuals
                </Dropdown.Toggle> */}
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to='/user-guide' className="d-flex align-items-center">
                    {/* <BusFront className="me-2" />  */}
                    Transportation
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to='/' className="d-flex align-items-center">
                    {/* <GpuCard className="me-2" />  */}
                    Industrial
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to='/' className="d-flex align-items-center">
                    {/* <AirplaneEngines className="me-2" />  */}
                    UAS
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to='/' className="d-flex align-items-center">
                    {/* <Robot className="me-2" />  */}
                    UGV
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Nav.Link as={Link} to="/careers">
                <PersonPlus className="me-2" /> Careers
              </Nav.Link>

              <Nav.Link as={Link} to="/about">
                <BodyText className="me-2" /> About
              </Nav.Link>

              <Nav.Link as={Link} to="/contacts">
                <ChatRight className="me-2" /> Contacts
              </Nav.Link>
              
            </Nav>
          {/* </Navbar.Collapse> */}
        </Container>
      </Navbar>

      {/* Hero Section */}
      <section className="hero-section text-center text-light d-flex align-items-center">
        <Container>
          <h1 className="display-4">Transforming Operations with Data and Analytics.</h1>
          <p className="lead">Redefining your operations - Efficiently.</p>
          {/* <Button variant="primary" size="lg" className="mt-4">Get Started</Button> */}
        </Container>
      </section>

      {/* Product Features Section */}
      <section className="features-section py-5">
        <Container>
          <Row>
            {products.map((product, index) => (
              <Col md={6} lg={3} className="mb-4" key={index}>
                <Card className="feature-card text-center">
                  <Card.Body>
                    {product.icon}
                    <Card.Title>{product.title}</Card.Title>
                    <Card.Text className='text-left' >{product.description}</Card.Text>
                    <Nav.Link as={Link} to={product.path}>
                      <Button variant="outline-primary">Learn More</Button>
                    </Nav.Link>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default LandingPage;
