import React, { useState, useEffect } from 'react';
import './TeacherSection.css';
import { Avatar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function VehicleBadge({ vehicle }) {
  const getVehicleClass = (vehicle) => {
    switch (vehicle) {
      case 'Bus 1':
        return 'teacher-section__vehicle--bus1';
      case 'Bus 2':
        return 'teacher-section__vehicle--bus2';
      case 'Bus 3':
        return 'teacher-section__vehicle--bus3';
      case 'Bus 4':
        return 'teacher-section__vehicle--bus4';
      default:
        return 'teacher-section__vehicle--bus4';
    }
  };

  return (
    <span className={`teacher-section__details-badge ${getVehicleClass(vehicle)}`}>
      {vehicle}
    </span>
  );
}

function StudentDetails({ student }) {
  return (
    <div className="teacher-section__details">
      <div className="teacher-section__student-image">
        <Avatar sx={{ width: 150, height: 150 }} src={student.image_url}>
          {!student.image_url && <PersonIcon sx={{ fontSize: 80 }} />}
        </Avatar>
      </div>
      <h2 className="teacher-section__details-title">Student Details</h2>
      <p className="teacher-section__details-info"><strong>Admission Number:</strong> {student.adm_number || '__'}</p>
      <p className="teacher-section__details-info"><strong>Zone:</strong> <span className={`teacher-section__details-badge teacher-section__details-badge--${student.zone?.toLowerCase() || 'unknown'}`}>{student.zone || '__'}</span></p>
      <p className="teacher-section__details-info"><strong>Grade:</strong> <span className={`teacher-section__details-badge teacher-section__details-badge--${student.grade?.toLowerCase() || 'unknown'}`}>{student.grade || '__'}</span></p>
      <p className="teacher-section__details-info">
        <strong>Morning Vehicle:</strong> <VehicleBadge vehicle={student.morning_vehicle || '__'} />
      </p>
      <p className="teacher-section__details-info">
        <strong>Afternoon Vehicle:</strong> <VehicleBadge vehicle={student.afternoon_vehicle || '__'} />
      </p>
      <p className="teacher-section__details-info"><strong>Pickup Time:</strong> <span className={`teacher-section__details-badge teacher-section__details-badge--${student.pickup_time?.toLowerCase() || 'unknown'}`}>{student.pickup_time || '__'}</span></p>
      <p className="teacher-section__details-info"><strong>Departure Time:</strong> <span className={`teacher-section__details-badge teacher-section__details-badge--${student.departure_time?.toLowerCase() || 'unknown'}`}>{student.departure_time || '__'}</span></p>
      <p className="teacher-section__details-info">
        <strong>Payment Status:</strong> 
        <span className={`teacher-section__details-badge teacher-section__details-badge--${student.payment_status?.toLowerCase() || 'unknown'}`}>
          {student.payment_status || '__'}
        </span>
      </p>
      <p className="teacher-section__details-info"><strong>Parent Contact 1:</strong> <span className={`teacher-section__details-badge teacher-section__details-badge--${String(student.parent_contact.contact1)?.toLowerCase() || 'unknown'}`}>{student.parent_contact.contact1 || '__'}</span></p>
      <p className="teacher-section__details-info"><strong>Parent Contact 2:</strong> <span className={`teacher-section__details-badge teacher-section__details-badge--${String(student.parent_contact.contact2)?.toLowerCase() || 'unknown'}`}>{student.parent_contact.contact2 || '__'}</span></p>
    </div>
  );
}

function TeacherSection() {
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const apiEndpoint = process.env.REACT_APP_API_DBRW_ENDPOINT;
        const response = await axios.get(`${apiEndpoint}/view-and-edit-student-data`);
        setStudents(response.data);
      } catch (error) {
        console.error('Failed to fetch students:', error);
      }
    };

    fetchStudents();
  }, []);

  const handleStudentClick = (student) => {
    setSelectedStudent(selectedStudent?.adm_number === student.adm_number ? null : student);
  };

  const filteredStudents = students.filter((student) =>
    student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    student.adm_number.includes(searchTerm)
  );

  const sortedStudents = [...filteredStudents].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="teacher-section">
      <div className="teacher-section__header">
        <button 
          className="check-in-screen__back-button"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
        <input
          type="text"
          className="teacher-section__search"
          placeholder="Search by name or admission number."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="teacher-section__main">
        <div className="teacher-section__table-section">
          <table className="teacher-section__table">
            <thead>
              <tr>
                <th onClick={() => handleSort('name')}>Name</th>
                <th onClick={() => handleSort('adm_number')}>Number</th>
                <th onClick={() => handleSort('zone')}>Zone</th>
                <th onClick={() => handleSort('afternoon_vehicle')}>Afternoon Vehicle</th>
              </tr>
            </thead>
            <tbody>
              {sortedStudents.map((student) => (
                <React.Fragment key={student.adm_number}>
                  <tr
                    onClick={() => handleStudentClick(student)}
                    className={selectedStudent?.adm_number === student.adm_number ? 'selected' : ''}
                  >
                    <td>{student.name}</td>
                    <td>{student.adm_number}</td>
                    <td>{student.zone}</td>
                    <td><VehicleBadge vehicle={student.afternoon_vehicle} /></td>
                  </tr>
                  {selectedStudent?.adm_number === student.adm_number && (
                    <tr className="teacher-section__details-row">
                      <td colSpan="4">
                        <StudentDetails student={student} />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TeacherSection;
