import React from 'react';
import { Button, Grid, Paper, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './AdminStaffMgt.css';

function AdminStaffMgt() {
    const navigate = useNavigate();

    return (
        <div className="admin-staff-mgt-container">
            <div className="header-section">
                <Button 
                    variant="contained"
                    onClick={() => navigate(-1)} 
                    sx={{ 
                        backgroundColor: '#232F3E', 
                        color: '#FFFFFF', 
                        '&:hover': { backgroundColor: '#1D2731' },
                        fontSize: 'clamp(14px, 1.5vw, 14px)',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        textTransform: 'none',
                    }}
                >
                    Back
                </Button>
            </div>

            <Box sx={{ flexGrow: 1, mt: 4 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} className="admin-staff-card">
                            <Typography variant="h6" className="admin-staff-title">
                                Communicate with Teachers and Stewards
                            </Typography>
                            <Typography variant="body1" className="admin-staff-description">
                                Use the platform to send messages and updates to teachers and stewards.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} className="admin-staff-card">
                            <Typography variant="h6" className="admin-staff-title">
                                Create Reminders
                            </Typography>
                            <Typography variant="body1" className="admin-staff-description">
                                Set up reminders for important tasks and events.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} className="admin-staff-card">
                            <Typography variant="h6" className="admin-staff-title">
                                Onboard and Manage Staff
                            </Typography>
                            <Typography variant="body1" className="admin-staff-description">
                                Onboard new teachers and stewards and manage their information.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default AdminStaffMgt;
