import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './routine.css';
import { Avatar, Button, Box, IconButton } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CallIcon from '@mui/icons-material/Call';
import TextsmsIcon from '@mui/icons-material/Textsms';
import axios from 'axios';

function StudentDetails({ student, updateLocation, handleCallParent, handleTextParent }) {
  // eslint-disable-next-line no-unused-vars
  const [location, setLocation] = useState(student.afternoon_trip_status?.place_address || '');
  const [notification, setNotification] = useState('');

  const handleLocationUpdate = async () => {
    const dummyCoordinates = "37.7749, -122.4194"; // Dummy coordinates for example
    await updateLocation(student.adm_number, location, dummyCoordinates);
    setNotification('Location updated successfully!');
    setTimeout(() => setNotification(''), 2000); // Clear notification after 3 seconds
  };

  return (
    <div className="check-in-screen__details">
      <div className="check-in-screen__student-image">
        <Avatar sx={{ width: 150, height: 150 }} src={student.image_url}>
          {!student.image_url && <PersonIcon sx={{ fontSize: 80 }} />}
        </Avatar>
      </div>
      <h2 className="check-in-screen__details-title">Student Details</h2>
      <p className="check-in-screen__details-info"><strong>Zone:</strong> {student.zone || '__'}</p>
      <p className="check-in-screen__details-info"><strong>Grade:</strong> {student.grade || '__'}</p>
      <p className="check-in-screen__details-info"><strong>Dropoff Time:</strong> {student.afternoon_trip_status?.dropoff_time || '__'}</p>
      <p className="check-in-screen__details-info"><strong>Departure Time:</strong> {student.departure_time || '__'}</p>
      <p className="check-in-screen__details-info"><strong>Payment Status:</strong> <span className={`check-in-screen__details-badge check-in-screen__details-badge--${student.payment_status?.toLowerCase() || 'unknown'}`}>{student.payment_status || '__'}</span></p>
      <p className="check-in-screen__details-info"><strong>Parent Contact 1:</strong> {student.parent_contact.contact1 || '__'}</p>
      <p className="check-in-screen__details-info"><strong>Parent Contact 2:</strong> {student.parent_contact.contact2 || '__'}</p>
      <Button variant="contained" color="primary" onClick={handleLocationUpdate}>
        Update location
      </Button>
      {notification && <p className="notification">{notification}</p>}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
        <Button variant="outlined" color="primary" onClick={() => handleCallParent(student.parent_contact.contact1)} startIcon={<CallIcon />}>
          parent 1
        </Button>
        <Button variant="outlined" color="primary" onClick={() => handleTextParent(student.parent_contact.contact1)} startIcon={<TextsmsIcon />} sx={{ marginLeft: 1 }}>
          parent 1
        </Button>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
        <Button variant="outlined" color="primary" onClick={() => handleCallParent(student.parent_contact.contact2)} startIcon={<CallIcon />}>
          parent 2
        </Button>
        <Button variant="outlined" color="primary" onClick={() => handleTextParent(student.parent_contact.contact2)} startIcon={<TextsmsIcon />} sx={{ marginLeft: 1 }}>
          parent 2
        </Button>
      </Box>
    </div>
  );
}

function AfternoonRoutine() {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [checkInStatus, setCheckInStatus] = useState({});

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const apiEndpoint = process.env.REACT_APP_API_DBRW_ENDPOINT;
        const response = await axios.get(`${apiEndpoint}/view-and-edit-student-data`);
        setStudents(response.data);
      } catch (error) {
        console.error('Failed to fetch students:', error);
      }
    };

    fetchStudents();
  }, []);

  const handleStudentClick = (student) => {
    setSelectedStudent(selectedStudent?.adm_number === student.adm_number ? null : student);
  };

  const handleCheckIn = (adm_number) => {
    setCheckInStatus((prevStatus) => ({
      ...prevStatus,
      [adm_number]: prevStatus[adm_number] === 'checked_in' ? 'checked_out' : 'checked_in',
    }));
  };

  const handleCallParent = (contact) => {
    if (contact) {
        window.location.href = `tel:${contact}`;
    } else {
        alert('No contact number available.');
    }
  };

  const handleTextParent = (contact) => {
    if (contact) {
        window.location.href = `sms:${contact}`;
    } else {
        alert('No contact number available.');
    }
  };

  const updateLocation = async (adm_number, location, coordinates) => {
    try {
      const apiEndpoint = process.env.REACT_APP_API_DBRW_ENDPOINT;
      await axios.put(`${apiEndpoint}/update-location`, { adm_number, location, coordinates });
      setStudents((prevStudents) =>
        prevStudents.map((student) =>
          student.adm_number === adm_number
            ? { ...student, afternoon_trip_status: { ...student.afternoon_trip_status, place_address: location, coordinates } }
            : student
        )
      );
    } catch (error) {
      console.error('Failed to update location:', error);
    }
  };

  const filteredStudents = students.filter((student) =>
    student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    student.adm_number.includes(searchTerm)
  );

  return (
    <div className="check-in-screen">
      <div className="check-in-screen__header">
        <button 
          className="check-in-screen__back-button"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
        <input
          type="text"
          className="check-in-screen__search"
          placeholder="Search by name or admission number."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="check-in-screen__main">
        <div className="check-in-screen__table-section">
          <table className="check-in-screen__table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Adm. Number</th>
                <th>Action</th>
                <th>Expand</th>
              </tr>
            </thead>
            <tbody>
              {filteredStudents.map((student) => (
                <React.Fragment key={student.adm_number}>
                  <tr className={selectedStudent?.adm_number === student.adm_number ? 'selected' : ''}>
                    <td>{student.name}</td>
                    <td>{student.adm_number}</td>
                    <td>
                      <button
                        className={
                          checkInStatus[student.adm_number] === 'checked_in'
                            ? 'check-in-screen__check-out-button'
                            : 'check-in-screen__check-in-button'
                        }
                        onClick={() => handleCheckIn(student.adm_number)}
                      >
                        {checkInStatus[student.adm_number] === 'checked_in' ? 'CheckOut' : 'CheckIn'}
                      </button>
                    </td>
                    <td>
                      <IconButton onClick={() => handleStudentClick(student)} sx={{ marginLeft: 1 }}>
                        {selectedStudent?.adm_number === student.adm_number ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </td>
                  </tr>
                  {selectedStudent?.adm_number === student.adm_number && (
                    <tr className="check-in-screen__details-row">
                      <td colSpan="4">
                        <StudentDetails student={student} updateLocation={updateLocation} handleCallParent={handleCallParent} handleTextParent={handleTextParent} />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <button className="check-in-screen__complete-button">
        Complete Boarding
      </button>
    </div>
  );
}

export default AfternoonRoutine;
