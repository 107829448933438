// LogisticsPlatformHome.jsx
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './LogisticsHome.css';
import { Link, useNavigate } from "react-router-dom";
import { Button, Grid, Paper, Typography, Box } from '@mui/material';
import { PersonLinesFill, Speedometer2, CameraVideo } from 'react-bootstrap-icons';

function LogisticsPlatformHome() {
    const navigate = useNavigate();

    const offerings = [
        {
            name: "Comprehensive Interface - DBMS",
            icon: <PersonLinesFill size={48} />,
            description: (
                <>
                    <p>Transform your student management into a seamless digital experience! Our system handles everything; A to Z</p>
                    <ul>
                        <li><strong>Digitized Profiles:</strong> Keep all student info in one place - from transport preferences to special needs</li>
                        <li><strong>Seamless Onboarding:</strong> Say goodbye to paperwork with our streamlined registration process</li>
                        <li><strong>One Platform, Connected Community:</strong> Keep teachers, parents, and transport staff in perfect sync</li>
                    </ul>
                    <p>Our intelligent route planner matches students with the perfect bus routes automatically - saving you hours of planning time.</p>
                </>
            ),
            shortDescription: "A comprehensive system for managing student profiles, transportation preferences, and route assignments with automated planning features.",
            path: "/loginInterface"
        },
        {
            name: "Vehicle Odometer",
            icon: <Speedometer2 size={48} />,
            description: (
                <>
                    <p>This isn't just another mileage counter - it's your fleet's personal fitness tracker.</p>
                    <ul>
                        <li><strong>Real-time Insights:</strong> Watch your fleet's performance live</li>
                        <li><strong>Cost Control:</strong> Track fuel usage and optimize expenses</li>
                        <li><strong>Predictive Care:</strong> Know exactly when each vehicle needs maintenance</li>
                    </ul>
                    <p>Cut costs, extend the vehicle's life, and make informed, data-driven decisions that keep your fleet running at peak efficiency.</p>
                </>
            ),
            shortDescription: "Real-time tracking system for vehicle mileage, fuel consumption, and fleet management optimization.",
            path: "/vehicle-odometer"
        },
        {
            name: "Vehicle Live Video",
            icon: <CameraVideo size={48} />,
            description: (
                <>
                    <p>Keep an eye on your fleet from anywhere! This isn't just video monitoring - it's your safety and compliance guardian.</p>
                    <ul>
                        <li><strong>Real-time Oversight:</strong> Monitor driver compliance and student safety live</li>
                        <li><strong>Instant Response:</strong> Address incidents the moment they happen</li>
                        <li><strong>Smart Archives:</strong> Everything recorded and organized for training and documentation</li>
                    </ul>
                    <p>Rest assured of fleet protection with state-of-the-art video security and compliance monitoring.</p>
                </>
            ),
            shortDescription: "Real-time video monitoring system for vehicle operations, safety compliance, and incident management.",
            path: "/vehicle-live-video"
        }
    ];

    return (
        <div className="container-fluid mt-4">
            <div className="header-section">
                <Button 
                    variant="contained"
                    onClick={() => navigate('/')} 
                    sx={{ 
                        backgroundColor: '#232F3E', 
                        color: '#FFFFFF', 
                        '&:hover': { backgroundColor: '#1D2731' },
                        fontSize: 'clamp(14px, 1.5vw, 14px)',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        textTransform: 'none',
                    }}
                >
                    Back
                </Button>

                <Typography variant="h6" className="project-title">
                    Project Salama &copy;
                </Typography>
                <Typography variant="p" className="project-title">
                    v2.0.0
                </Typography>
            </div>
            
            <Box sx={{ flexGrow: 1, mt: 4 }}>
                {offerings.map((offering, index) => (
                    <Grid container spacing={4} className="offering-row" key={index}>
                        <Grid item xs={12} md={6}>
                            <Link to={offering.path} className="text-decoration-none">
                                <Paper elevation={3} className="offering-card">
                                    <div className="offering-content">
                                        <div className="icon-container">
                                            {offering.icon}
                                        </div>
                                        <Typography variant="h6" className="offering-title">
                                            {offering.name}
                                        </Typography>
                                    </div>
                                </Paper>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={6} className="description-column">
                            <div className="description-content">
                                <Typography variant="body1" className="offering-description">
                                    <span className="full-description">
                                        {offering.description}
                                    </span>
                                    <span className="short-description">
                                        {offering.shortDescription}
                                    </span>
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                ))}
            </Box>
        </div>
    );
}

export default LogisticsPlatformHome;