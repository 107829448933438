import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import logo from '../../assets/logoNew2.png';

function formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function generateInvoiceNumber(month, distance, cost) {
    const monthNumber = new Date(Date.parse(month + " 1, 2000")).getMonth() + 1;
    const year = new Date().getFullYear() % 100;
    const distanceDigit = Math.floor(distance) % 10;
    const costDigit = Math.floor(cost) % 10;
    return `${year}${monthNumber.toString().padStart(2, '0')}${distanceDigit}${costDigit}`;
}

export function generatePDF(month, distance, cost) {
    const doc = new jsPDF();
    const invoiceNumber = generateInvoiceNumber(month, distance, cost);
    const invoiceDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    const dueDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

    const primaryColor = [35, 47, 62];
    const secondaryColor = [0, 0, 0];
    const lightGray = [200, 200, 200];
    const darkGray = [100, 100, 100];

    function drawBorderedBox(doc, x, y, width, height, color) {
        doc.setDrawColor(...color);
        doc.rect(x, y, width, height);
    }

    doc.addImage(logo, 'PNG', 15, 15, 40, 40);

    const invoiceDetailsX = 120;
    const invoiceDetailsY = 15;
    const invoiceDetailsWidth = 80;
    const invoiceDetailsHeight = 30;

    drawBorderedBox(doc, invoiceDetailsX, invoiceDetailsY, invoiceDetailsWidth, invoiceDetailsHeight, lightGray);

    doc.setFontSize(9);
    doc.setTextColor(...darkGray);
    doc.setFont('helvetica', 'normal');

    doc.text("Invoice Number:", invoiceDetailsX + 5, invoiceDetailsY + 8);
    doc.text("Date Generated:", invoiceDetailsX + 5, invoiceDetailsY + 16);
    doc.text("Due Date:", invoiceDetailsX + 5, invoiceDetailsY + 24);

    doc.setTextColor(...primaryColor);
    doc.setFont('helvetica', 'bold');
    doc.text(invoiceNumber, invoiceDetailsX + invoiceDetailsWidth - 5, invoiceDetailsY + 8, null, null, 'right');
    doc.text(invoiceDate, invoiceDetailsX + invoiceDetailsWidth - 5, invoiceDetailsY + 16, null, null, 'right');
    doc.text(dueDate, invoiceDetailsX + invoiceDetailsWidth - 5, invoiceDetailsY + 24, null, null, 'right');

    doc.setFontSize(10);
    doc.setTextColor(...primaryColor);
    doc.setFont('helvetica', 'bold');
    doc.text('From:', 15, 65);
    doc.setFont('helvetica', 'normal');
    doc.text('Data-IM', 15, 72);
    doc.text('254 115 566 678', 15, 79);
    doc.text('enquiry@data-im.com', 15, 86);
    doc.text('Karen Village, Nairobi, Kenya', 15, 93);

    doc.setFont('helvetica', 'bold');
    doc.text('Bill To:', 120, 65);
    doc.setFont('helvetica', 'normal');
    doc.text('Integrity Academy', 120, 72);
    doc.text('254 721 306 449', 120, 79);
    doc.text('integrity.academy@yahoo.com', 120, 86);
    doc.text('Naivasha, Kenya.', 120, 93);

    doc.setFont('helvetica', 'bold');
    doc.text('Ship To:', 120, 107);
    doc.setFont('helvetica', 'normal');
    doc.text('Naivasha, Kenya.', 120, 114);

    doc.autoTable({
        startY: 125,
        head: [['DESCRIPTION', 'PERIOD', 'DISTANCE', 'AMOUNT (KES)']],
        body: [
            [`Billing Period`, `${month}`, '',  ''],
            [`Total Distance`,'', `${formatNumber(distance.toFixed(0))} km`, ''],
            ['Total Cost', '', '', formatNumber(cost.toFixed(0))],
        ],
        theme: 'striped',
        headStyles: { fillColor: primaryColor, textColor: 255 },
        alternateRowStyles: { fillColor: [247, 247, 247] },
        columnStyles: {
            0: { cellWidth: 60 },
            1: { cellWidth: 40 },
            2: { cellWidth: 40 },
            3: { cellWidth: 40 },
        },
    });

    const paymentY = doc.autoTable.previous.finalY + 20;
    doc.setFontSize(10);
    doc.setTextColor(...primaryColor);
    doc.setFont('helvetica', 'bold');
    doc.text('Payment Method:', 15, paymentY);
    doc.setFont('helvetica', 'normal');
    doc.text('Safaricom M-PESA Transfer', 15, paymentY + 7);
    doc.text('Paybill No. 400200', 15, paymentY + 14);
    doc.text('Account No. 25411556667801', 15, paymentY + 21);

    const totalsY = paymentY;
    doc.setFont('helvetica', 'bold');
    doc.text('Subtotal:', 140, totalsY);
    doc.text('Tax (0%):', 140, totalsY + 7);
    doc.text('Total:', 140, totalsY + 14);
    doc.text('Amount Paid:', 140, totalsY + 21);
    doc.text('Balance Due:', 140, totalsY + 28);

    const textWidth = doc.getTextWidth('Balance Due:');
    doc.setDrawColor(...secondaryColor);
    doc.line(140, totalsY + 29, 140 + textWidth, totalsY + 29);

    doc.setFont('helvetica', 'normal');
    doc.text(`${formatNumber(cost.toFixed(0))}.00`, 200, totalsY, null, null, 'right');
    doc.text('0.00', 200, totalsY + 7, null, null, 'right');
    doc.text(`${formatNumber(cost.toFixed(0))}.00`, 200, totalsY + 14, null, null, 'right');
    doc.text('0.00', 200, totalsY + 21, null, null, 'right');
    doc.setFont('helvetica', 'bold');
    doc.text(`KES ${formatNumber(cost.toFixed(0))}.00`, 200, totalsY + 28, null, null, 'right');

    const footerY = doc.internal.pageSize.height - 50;
    doc.setFontSize(9);
    doc.setFont('helvetica', 'bold');
    doc.text('Notes:', 15, footerY);
    doc.setFont('helvetica', 'normal');
    doc.text('', 15, footerY + 7);

    doc.save(`${month}_invoice.pdf`);
}
