import React, { useState, useEffect } from 'react';
import './LoginInterface.css';
import { Button, Box, TextField, Typography, IconButton, InputAdornment, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Amplify } from 'aws-amplify';
// eslint-disable-next-line
import { signIn } from 'aws-amplify/auth';
import awsExports from '../../aws-exports';
import salamaLogo from '../../assets/salama.svg';

Amplify.configure(awsExports);

const LoginInterface = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [role, setRole] = useState('');
    const [schoolId, setSchoolId] = useState('');
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleEmailInput = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordInput = (e) => {
        setPassword(e.target.value);
    };

    const handleRoleSelect = (selectedRole) => {
        setRole(selectedRole);
    };

    const handleSchoolIdInput = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
        if (value.length <= 10) { // Limit to 10 digits
            setSchoolId(value);
        }
    };

    const handleLogin = async () => {
        try {
            if (!role) {
                setAlertOpen(true);
                return;
            }
            // await signIn(email, password);
            if (role === 'admin') {
                navigate('/admin');
            } else {
                navigate('/nonAdminInterface');
            }
        } catch (error) {
            console.error('Error signing in', error);
        }
    };

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                setDeferredPrompt(null);
            });
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="page-container">
            <div className="header-section">
                <Button 
                    variant="contained"
                    onClick={() => navigate('/lph')}
                    sx={{ 
                        backgroundColor: '#232F3E', 
                        color: '#FFFFFF', 
                        '&:hover': { backgroundColor: '#1D2731' },
                        fontSize: 'clamp(14px, 1.5vw, 14px)',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        textTransform: 'none',
                        margin: '20px',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                    }}
                >
                    Back
                </Button>
                {deferredPrompt && (
                    <Button 
                        variant="outlined"
                        onClick={handleInstallClick} 
                        startIcon={<InstallMobileIcon />}
                        sx={{ 
                            borderColor: '#FF9900', 
                            color: '#FF9900', 
                            '&:hover': { 
                                borderColor: '#e68a00', 
                                color: '#e68a00' 
                            },
                            fontSize: 'clamp(14px, 1.5vw, 14px)',
                            borderRadius: '10px',
                            padding: '8px 16px',
                            textTransform: 'none',
                            margin: '20px',
                            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                            position: 'absolute',
                            right: '20px',
                        }}
                    >
                        Install
                    </Button>
                )}
            </div>
            
            <div className="content-container" style={{ flex: '0 1 auto' }}>
                {alertOpen && (
                    <Alert severity="warning" onClose={() => setAlertOpen(false)} sx={{ mb: 2 }}>
                        Please select a role.
                    </Alert>
                )}
                <img src={salamaLogo} alt="Salama Logo" className="logo" style={{ width: '300px', marginBottom: '30px' }} />
                <Box className="role-container" sx={{ width: { xs: '100%', sm: '80%', md: '60%', lg: '40%' }, mb: 2 }}>
                    <Typography variant="p" sx={{ textAlign: 'center', mb: 2, fontWeight: 'bold' }}>Select Role</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 2 }}>
                        {['Admin', 'Teacher', 'Steward'].map((roleOption) => (
                            <Button
                                key={roleOption}
                                variant={role === roleOption.toLowerCase() ? 'contained' : 'outlined'}
                                onClick={() => handleRoleSelect(roleOption.toLowerCase())}
                                sx={{
                                    backgroundColor: role === roleOption.toLowerCase() ? '#FF9900' : 'transparent',
                                    color: role === roleOption.toLowerCase() ? '#FFFFFF' : '#000000',
                                    borderColor: role === roleOption.toLowerCase() ? '#FF9900' : '#000000',
                                    '&:hover': { 
                                        backgroundColor: role === roleOption.toLowerCase() ? '#e68a00' : '#FF9900',
                                        color: '#FFFFFF',
                                        borderColor: '#FF9900'
                                    },
                                    fontSize: 'clamp(14px, 1.5vw, 14px)',
                                    borderRadius: '25px',
                                    padding: '12px 24px',
                                    textTransform: 'none',
                                    flex: 1,
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                }}
                            >
                                {roleOption}
                            </Button>
                        ))}
                    </Box>
                </Box>
                <Box className="login-card" sx={{ width: { xs: '100%', sm: '80%', md: '60%', lg: '40%' } }}>
                    <TextField
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailInput}
                        className="email-input"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        sx={{ mb: 2, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'black' } } }}
                    />
                    <TextField
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordInput}
                        className="password-input"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePasswordVisibility}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        sx={{ mb: 2, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'black' } } }}
                    />
                    <TextField
                        type="text"
                        placeholder="School ID"
                        value={schoolId}
                        onChange={handleSchoolIdInput}
                        className="school-id-input"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        inputProps={{ maxLength: 10 }} // Limit input to 10 digits
                        sx={{ mb: 2, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'black' } } }}
                    />

                    <Box sx={{ width: '100%', mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Button 
                            variant="contained"
                            onClick={handleLogin}
                            className="login-button"
                            sx={{ 
                                backgroundColor: '#FF9900', 
                                color: '#ffffff', 
                                '&:hover': { backgroundColor: '#e68a00' },
                                fontSize: 'clamp(14px, 1.5vw, 16px)',
                                borderRadius: '25px',
                                padding: '12px 24px',
                                textTransform: 'none',
                                width: '200px',
                                margin: '0 auto',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                            }}
                        >
                            Login
                        </Button>
                    </Box>
                </Box>
            </div>
        </div>
    );
};

export default LoginInterface;