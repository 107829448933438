import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage/LandingPage';
import LogisticsPlatformHome from './pages/LogisticsHome/LogisticsHome';
import About from './pages/About/About';
import Careers from './pages/Careers/Careers';
import Guide from './pages/Guide/Guide';
import Contacts from './pages/Contacts/Contacts';

import Industrial from './pages/IndustrialPage/Industrial';

import VehicleOdometerDetail from './pages/Odometer/VehicleOdometerDetails';
import VehicleDetailPage from './pages/Odometer/VehicleDetailPage';

import AdminHome from './pages/AdminPage/AdminHome';
import AdminStaffMgt from './ui/ASM/AdminStaffMgt';
import StudentRegistrationForm from './components/onboarding/StudentRegistrationForm';
import ViewEditStudentRecords from './components/onboarding/ViewEditStudentRecords';
import CustomMap from './components/maps/vehicleRoute';
  
// UI Play - might use later
import TeacherSection from './ui/TeacherSection/TeacherSection';
import LoginInterface from './ui/LoginInterface/LoginInterface';
import NonAdminInterface from './ui/NonAdminInterface/NonAdminInterface';

import MorningRoutine from './ui/Morning/routine';
import AfternoonRoutine from './ui/Afternoon/routine';

import QRScannerPage from './ui/NonAdminInterface/QRScannerPage';
import QRScannerResultPage from './ui/NonAdminInterface/QRScannerResultPage';

const App = () => {
  return (
    <Router>
      <div className="d-flex">
        <div className="content-area flex-grow-1">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/lph" element={<LogisticsPlatformHome />} />
            <Route path="/about" element={<About />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/user-guide" element={<Guide />} />
            <Route path="/contacts" element={<Contacts />} />

            <Route path="/vehicle-odometer" element={<VehicleOdometerDetail />} />
            <Route path="/vehicle-odometer/:deviceId" element={<VehicleDetailPage />} />

            <Route path="/admin" element={<AdminHome />} />
            <Route path="/admin-staff-management" element={<AdminStaffMgt />} />
            <Route path="/onboard-student" element={<StudentRegistrationForm />} />
            <Route path="/view-student-records" element={<ViewEditStudentRecords />} />
            <Route path='/vehicle-route' element={<CustomMap />} />

            <Route path="/industrial" element={<Industrial />} />

            {/* <Route path="/boarding" element={<afternoonTripPage />} /> */}
            <Route path="/afternoon-trip" element={<AfternoonRoutine/>} />
            <Route path="/morning-trip" element={<MorningRoutine />} />
            <Route path="/teachers-section" element={<TeacherSection />} />
            
            <Route path='/loginInterface' element={<LoginInterface />} />
            <Route path='/nonAdminInterface' element={<NonAdminInterface />} />
            <Route path="/qr-scanner" element={<QRScannerPage />} />
            <Route path="/qr-scanner-result" element={<QRScannerResultPage />} />
            
            
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
