import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './AdminHome.css';
import { Link, useNavigate } from "react-router-dom";
import { Button, Grid, Paper, Typography, Box } from '@mui/material';
import { PersonPlus, PencilSquare, SignpostSplit, PersonVcard } from 'react-bootstrap-icons';

const offerings = [
    { 
        name: "Onboard Student", 
        icon: <PersonPlus size={48} />, 
        description: (
            <>
                <p>Register new students for the transport system, assigning routes and schedules.</p>
                <ul>
                    <li><strong>Efficient Registration:</strong> Streamline the onboarding process with minimal paperwork.</li>
                    <li><strong>Route Assignment:</strong> Assign students to appropriate routes based on their location.</li>
                    <li><strong>Schedule Management:</strong> Manage student schedules effectively to ensure timely pickups and drop-offs.</li>
                </ul>
                <p>Ensure a smooth onboarding experience for new students and their parents.</p>
            </>
        ),
        shortDescription: "Register new students for the transport system, assigning routes and schedules.",
        path: "/onboard-student"
    },
    { 
        name: "View or Edit Student Records", 
        icon: <PencilSquare size={48} />, 
        description: (
            <>
                <p>View detailed student records including attendance and route history while</p>
                <ul>
                    <li><strong>Comprehensive Records:</strong> Access detailed student information at your fingertips.</li>
                    <li><strong>Attendance Tracking:</strong> Monitor student attendance and identify patterns.</li>
                    <li><strong>Route History:</strong> Review historical route data to optimize future routes.</li>
                </ul>
                <p>Maintain accurate and up-to-date records for all students.</p>
            </>
        ),
        shortDescription: "View detailed student records including attendance and route history.",
        path: "/view-student-records"
    },
    { 
        name: "Vehicle Route", 
        icon: <SignpostSplit size={48} />, 
        description: (
            <>
                <p>Provides insights into vehicle journeys, including stop times and locations, essential for structured scheduling and punctuality.</p>
                <ul>
                    <li><strong>Journey Insights:</strong> Analyze vehicle stop times and locations to improve efficiency.</li>
                    <li><strong>Structured Scheduling:</strong> Ensure punctuality with detailed schedules and route planning.</li>
                    <li><strong>Route Optimization:</strong> Optimize routes to reduce travel time and fuel consumption.</li>
                </ul>
                <p>Enhance the overall efficiency and reliability of the transport system.</p>
            </>
        ),
        shortDescription: "Provides insights into vehicle journeys, including stop times and locations, essential for structured scheduling and punctuality.",
        path: "/vehicle-route"
    },
    { 
        name: "Admin Staff Management", 
        icon: <PersonVcard size={48} />, 
        description: (
            <>
                <p>Manage communication with teachers and stewards, create reminders, and onboard and manage the list of teachers and stewards.</p>
                <ul>
                    <li><strong>Effective Communication:</strong> Communicate efficiently with teachers and stewards through a unified platform.</li>
                    <li><strong>Create Reminders:</strong> Set up reminders for important tasks and events.</li>
                    <li><strong>Staff Onboarding:</strong> Onboard new teachers and stewards and manage their information.</li>
                </ul>
                <p>Ensure smooth management and communication with the admin staff.</p>
            </>
        ),
        shortDescription: "Manage communication with teachers and stewards, create reminders, and onboard and manage the list of teachers and stewards.",
        path: "/admin-staff-management"
    },
];

function AdminHome() {
    const navigate = useNavigate();

    return (
        <div className="admin-container-fluid mt-4">
            <div className="admin-header-section mb-3">
                <Button 
                    variant="contained"
                    onClick={() => navigate(-1)} 
                    sx={{ 
                        backgroundColor: '#232F3E', 
                        color: '#FFFFFF', 
                        '&:hover': { backgroundColor: '#1D2731' },
                        fontSize: 'clamp(14px, 1.5vw, 14px)',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        textTransform: 'none',
                    }}
                >
                    Back
                </Button>
            </div>

            <Box sx={{ flexGrow: 1, mt: 4 }}>
                {offerings.map((offering, index) => (
                    <Grid container spacing={4} className="offering-row" key={index}>
                        <Grid item xs={12} md={6}>
                            <Link to={offering.path} className="text-decoration-none">
                                <Paper elevation={3} className="offering-card">
                                    <div className="offering-content">
                                        <div className="icon-container">
                                            {offering.icon}
                                        </div>
                                        <Typography variant="h6" className="offering-title">
                                            {offering.name}
                                        </Typography>
                                    </div>
                                </Paper>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={6} className="description-column">
                            <div className="description-content">
                                <Typography variant="body1" className="offering-description">
                                    <span className="full-description">
                                        {offering.description}
                                    </span>
                                    <span className="short-description">
                                        {offering.shortDescription}
                                    </span>
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                ))}
            </Box>
        </div>
    );
}

export default AdminHome;
