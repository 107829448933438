import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './NonAdminInterface.css';
import { Link, useNavigate } from "react-router-dom";
import { Button, Grid, Paper, Typography, Box } from '@mui/material';
import { PersonUp, PersonDown, PersonRolodex } from 'react-bootstrap-icons';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

function NonAdminInterface({ signOut, user }) {
    const navigate = useNavigate();

    const handleScanTagClick = () => {
        navigate('/qr-scanner');
    };

    const offerings = [
        
        { 
            name: "Morning Trip", 
            icon: <PersonUp size={48} />, 
            description: (
                <>
                    <p>Ensure students to and from school board the right vehicle and are checked in safely.</p>
                    <ul>
                        <li><strong>Safe Boarding:</strong> Verify students boarding the correct vehicle.</li>
                        <li><strong>Real-time Tracking:</strong> Monitor the morning trip in real-time.</li>
                        <li><strong>Automated Alerts:</strong> Notify parents and teachers of delays or issues.</li>
                    </ul>
                </>
            ),
            shortDescription: "Ensure students board the right vehicle and are checked in safely during the morning trip.",
            path: "/morning-trip"
        },        
        { 
            name: "Afternoon Trip", 
            icon: <PersonDown size={48} />, 
            description: (
                <>
                    <p>Ensure students are checked out of vehicles safely and can be accounted for.</p>
                    <ul>
                        <li><strong>Safe Drop-off:</strong> Verify students are dropped off at the correct location.</li>
                        <li><strong>Attendance Tracking:</strong> Ensure all students are accounted for.</li>
                        <li><strong>Parent Notifications:</strong> Notify parents when students are safely dropped off.</li>
                    </ul>
                </>
            ),
            shortDescription: "Ensure students are checked out of vehicles safely and accounted for during the afternoon trip.",
            path: "/afternoon-trip"
        },
        { 
            name: "Teacher's Panel", 
            icon: <PersonRolodex size={48} />, 
            description: (
                <>
                    <p>Teachers in charge of a particular group of students/grade get instant updates should the logistics manager make any changes or updates.</p>
                    <ul>
                        <li><strong>Real-time Notifications:</strong> Get notified immediately about any changes.</li>
                        <li><strong>Student Tracking:</strong> Monitor student attendance and transportation details.</li>
                        <li><strong>Communication Hub:</strong> Stay connected with parents and logistics staff.</li>
                    </ul>
                </>
            ),
            shortDescription: "Teachers get instant updates on student transportation changes and manage their groups efficiently.",
            path: "/teachers-section"
        },      
    ];

    return (
        <div className="container-fluid mt-4">
            <div className="header-section">
                <Button 
                    variant="contained"
                    onClick={() => navigate('/loginInterface')}
                    sx={{ 
                        backgroundColor: '#232F3E', 
                        color: '#FFFFFF', 
                        '&:hover': { backgroundColor: '#1D2731' },
                        fontSize: 'clamp(14px, 1.5vw, 14px)',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        textTransform: 'none',
                        margin: '0px',
                        position: 'relative',
                        zIndex: 0
                    }}
                >
                    Back
                </Button>
            </div>
            
            <Box sx={{ flexGrow: 1, mt: 4 }}>
                {offerings.map((offering, index) => (
                    <Grid container spacing={4} className="offering-row" key={index}>
                        <Grid item xs={12} md={6}>
                            <Link to={offering.path} className="text-decoration-none">
                                <Paper elevation={3} className="offering-card">
                                    <div className="offering-content">
                                        <div className="icon-container">
                                            {offering.icon}
                                        </div>
                                        <Typography variant="h6" className="offering-title">
                                            {offering.name}
                                        </Typography>
                                    </div>
                                </Paper>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={6} className="description-column">
                            <div className="description-content">
                                <Typography variant="body1" className="offering-description">
                                    <span className="full-description">
                                        {offering.description}
                                    </span>
                                    <span className="short-description">
                                        {offering.shortDescription}
                                    </span>
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                ))}
            </Box>

            <div className="scan-button-container">
                <Button 
                    variant="contained"
                    onClick={handleScanTagClick} 
                    sx={{ 
                        backgroundColor: '#FF9900', 
                        color: '#FFFFFF', 
                        '&:hover': { backgroundColor: '#e68a00' },
                        fontSize: 'clamp(14px, 1.5vw, 16px)',
                        borderRadius: '25px',
                        padding: '12px 24px',
                        textTransform: 'none',
                        width: '200px',
                        position: 'fixed',
                        bottom: '20px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                    }}
                >
                    Scan Tag
                    <QrCodeScannerIcon sx={{ fontSize: '1.5rem', marginLeft: '10px' }} />
                </Button>
            </div>
        </div>
    );
}

export default NonAdminInterface;