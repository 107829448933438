import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import fetchDataFromDynamoDB from './DynamoDBService';
import { Button, Card, Typography, Grid, CircularProgress, 
    Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Paper, Alert, Box, Collapse, IconButton, Tooltip } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jspdf-autotable';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { Amplify } from 'aws-amplify';
import awsExports from '../../aws-exports';
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { signOut } from 'aws-amplify/auth';
import { generatePDF } from './InvoiceGenerator';

Amplify.configure(awsExports);

const theme = {
  name: 'custom-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          10: '#232F3E',
          80: '#FF9900',
          90: '#FFA726',
          100: '#FF9900',
        },
      },
      background: {
        primary: '#F7F7F7',
        secondary: '#FFFFFF',
      },
      font: {
        interactive: '#232F3E',
      },
    },
    components: {
      button: {
        primary: {
          backgroundColor: '#232F3E',
          _hover: {
            backgroundColor: '#1D2731',
          },
        },
      },
      heading: {
        color: '#232F3E',
      },
    },
  },
};


function VehicleOdometerDetail() {
    const [vehicleList, setVehicleList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [lastUpdatedTime, setLastUpdatedTime] = useState('');
    // eslint-disable-next-line
    const [totalDistance, setTotalDistance] = useState(0);
    const [monthlyData, setMonthlyData] = useState([]);
    // eslint-disable-next-line
    const [isFirstWeekOfMonth, setIsFirstWeekOfMonth] = useState(false);
    const [expanded, setExpanded] = useState(true);
    // const [annualExpanded, setAnnualExpanded] = useState(false);
    const [expandedYears, setExpandedYears] = useState({});
    const navigate = useNavigate();

    // Helper function to format numbers with commas
    const formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    // Sign Out function
    const handleSignOut = async () => {
        try {
            await signOut();
            navigate('/lph'); // Navigate to the home page or login page after sign out
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleYearExpandClick = (year) => {
        setExpandedYears((prev) => ({
            ...prev,
            [year]: !prev[year],
        }));
    };

    useEffect(() => {
        const fetchVehicles = async () => {
            try {
                const vehicles = await fetchDataFromDynamoDB();
                setVehicleList(vehicles);
                const currentTime = new Date().toLocaleTimeString();
                setLastUpdatedTime(currentTime);

                // Calculate total distance and monthly distances
                let total = 0;
                const monthlyDistances = {};
                const annualDistances = {};

                vehicles.forEach(vehicle => {
                    if (vehicle.monthlyDistances) {
                        vehicle.monthlyDistances.forEach(monthData => {
                            const distance = parseFloat(monthData.distance);
                            if (!isNaN(distance)) {
                                total += distance;
                                if (monthlyDistances[monthData.month]) {
                                    monthlyDistances[monthData.month] += distance;
                                } else {
                                    monthlyDistances[monthData.month] = distance;
                                }

                                const year = monthData.month.split(' ')[1];
                                if (annualDistances[year]) {
                                    annualDistances[year] += distance;
                                } else {
                                    annualDistances[year] = distance;
                                }
                            }
                        });
                    }
                });

                setTotalDistance(total);

                // Convert monthly distances to the required format
                const formattedMonthlyData = Object.entries(monthlyDistances).map(([month, distance]) => ({
                    month,
                    distance,
                    cost: distance * 2 // cost is 2 KES per km
                }));

                setMonthlyData(formattedMonthlyData);

                // Convert annual distances to the required format
                // const formattedAnnualData = Object.entries(annualDistances).reduce((acc, [year, distance]) => {
                //     acc[year] = {
                //         totalDistance: distance,
                //         totalCost: distance * 2 // cost is 2 KES per km
                //     };
                //     return acc;
                // }, {});

            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchVehicles();

        // Check if it's the first week of the month
        const now = new Date();
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const daysSinceFirstOfMonth = Math.floor((now - firstDayOfMonth) / (24 * 60 * 60 * 1000));
        setIsFirstWeekOfMonth(daysSinceFirstOfMonth < 7);
    }, []);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <CircularProgress sx={{ color: '#FF9900' }}/>
            </div>
        );
    }

    if (error) {
        return (
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                minHeight: '100vh',
                bgcolor: 'background.default',
                p: 3
            }}>
                <Paper elevation={3} sx={{ 
                    maxWidth: 400, 
                    width: '100%', 
                    p: 3, 
                    textAlign: 'center'
                }}>
                    <ErrorOutlineIcon sx={{ fontSize: 60, color: 'error.main', mb: 2 }} />
                    <Typography variant="h5" gutterBottom sx={{ color: 'text.primary' }}>
                        Error Loading Vehicles. Check Internet Connection.
                    </Typography>
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {error}
                    </Alert>
                </Paper>
            </Box>
        );
    }

    // Group monthly data by year
    const groupedByYear = monthlyData.reduce((acc, monthData) => {
        const year = monthData.month.split(' ')[1];
        if (!acc[year]) {
            acc[year] = [];
        }
        acc[year].push(monthData);
        return acc;
    }, {});

    return (
        <div className="container mt-4">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button 
                    variant="contained"
                    onClick={() => navigate(-1)} 
                    sx={{ 
                        mb: 3, 
                        backgroundColor: '#232F3E', 
                        color: '#FFFFFF', 
                        '&:hover': { backgroundColor: '#1D2731' },
                        fontSize: 'clamp(14px, 1.5vw, 14px)',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        textTransform: 'none',
                    }}
                    // startIcon={<i className="bi bi-arrow-left-circle"></i>}
                >
                    Back
                </Button>
                <Button 
                    variant="contained"
                    onClick={handleSignOut} 
                    sx={{ 
                        mb: 3, 
                        backgroundColor: '#232F3E', 
                        color: '#FFFFFF', 
                        '&:hover': { backgroundColor: '#1D2731' },
                        fontSize: 'clamp(14px, 1.5vw, 14px)',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        textTransform: 'none',
                    }}
                >
                    Sign Out
                </Button>
            </div>

            <Typography 
                variant="h5" 
                sx={{ 
                    color: '#FF9900', 
                    mb: 3, 
                    fontSize: 'clamp(18px, 3vw, 24px)', 
                    fontWeight: 'bold',
                    textAlign: 'center'
                }}
            >
                Registered Vehicles
            </Typography>
            <Grid container spacing={4}>
                {vehicleList.length > 0 ? (
                    vehicleList.map((vehicle, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <Link to={`/vehicle-odometer/${vehicle.deviceId}`} style={{ textDecoration: 'none' }}>
                                <Card sx={{ 
                                    height: '100%', 
                                    boxShadow: 3, 
                                    borderRadius: 2, 
                                    p: 2,
                                    backgroundColor: '#F7F7F7',
                                    '&:hover': {
                                        boxShadow: 6
                                    }
                                }}>
                                    <Typography 
                                        variant="h6" 
                                        sx={{ 
                                            color: '#232F3E', 
                                            fontWeight: 'bold',
                                            mb: 2,
                                            textAlign: 'center',
                                            fontSize: 'clamp(16px, 2vw, 20px)',
                                        }}
                                    >
                                        {vehicle.vehicleRegNumber}
                                    </Typography>
                                    <Typography 
                                        sx={{ 
                                            color: '#333333', 
                                            textAlign: 'center',
                                            fontSize: 'clamp(12px, 1.5vw, 14px)',
                                        }}
                                    >
                                        Total Distance Covered: {formatNumber(vehicle.totalDistanceCovered.toFixed(0))} km
                                    </Typography>
                                    <Typography 
                                        sx={{ 
                                            color: '#666666', 
                                            textAlign: 'center',
                                            fontSize: 'clamp(10px, 1.2vw, 12px)',
                                            mt: 1,
                                        }}
                                    >
                                        Last Updated: {lastUpdatedTime}
                                    </Typography>
                                </Card>
                            </Link>
                        </Grid>
                    ))
                ) : (
                    <Typography sx={{ color: '#333333', textAlign: 'center', width: '100%' }}>
                        No vehicles found.
                    </Typography>
                )}
            </Grid>
            <Typography 
                variant="h6" 
                sx={{ 
                    color: '#FF9900', 
                    mt: 4,
                    mb: 3, 
                    fontSize: 'clamp(18px, 3vw, 24px)', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                Monthly Records - Distance and Billing.
                <Tooltip title="Click to Expand/Collapse">
                    <IconButton
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        sx={{ 
                            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)', 
                            transition: 'transform 0.3s',
                            ml: 1
                        }}
                    >
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Tooltip>
            </Typography>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {/* Grouped Monthly Records Section */}
                {Object.entries(groupedByYear).map(([year, months]) => (
                    <Box key={year} sx={{ mb: 2 }}>
                        <Typography variant="h6" component="div" 
                        sx={{ 
                            color: '#FF9900', 
                            mt: 4,
                            mb: 3, 
                            fontSize: 'clamp(16px, 3vw, 20px)', 
                            fontWeight: 'bold',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'center'
                        }}>
                            {year} Records
                            <Tooltip title="Click to Expand/Collapse">
                                <IconButton
                                    onClick={() => handleYearExpandClick(year)}
                                    aria-expanded={expandedYears[year]}
                                    aria-label={`show ${year} records`}
                                    sx={{ 
                                        transform: expandedYears[year] ? 'rotate(180deg)' : 'rotate(0deg)', 
                                        transition: 'transform 0.3s',
                                        ml: 1
                                    }}
                                >
                                    {expandedYears[year] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </Tooltip>
                        </Typography>
                        <Collapse in={expandedYears[year]} timeout="auto" unmountOnExit>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ textAlign: 'center' }}>Month</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>Total Distance Covered (km)</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>Total Cost (KES)</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>Download Invoice</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {months.map((monthData, index) => {
                                            const now = new Date();
                                            const currentMonth = now.toLocaleString('default', { month: 'long' });
                                            const currentYear = now.getFullYear();
                                            const currentMonthYear = `${currentMonth} ${currentYear}`;
                                            const isFirstWeekOfMonth = now.getDate() <= 7;
                                            const previousMonthCumulative = index > 0 
                                                ? months.slice(0, index).reduce((sum, data) => sum + data.distance, 0) 
                                                : 0;
                                            const currentMonthCumulative = previousMonthCumulative + monthData.distance;                            
                                            const isCurrentMonth = monthData.month === currentMonthYear;

                                            return (
                                                <TableRow key={index}>
                                                    <TableCell sx={{ fontWeight: 'bold' }}>{monthData.month}</TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: 'clamp(12px, 1.5vw, 14px)' }}>
                                                        {formatNumber(previousMonthCumulative.toFixed(0))} - {formatNumber(currentMonthCumulative.toFixed(0))} km
                                                    </TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: 'clamp(12px, 1.5vw, 14px)' }}>
                                                        {formatNumber(monthData.cost.toFixed(0))}
                                                    </TableCell>
                                                    <TableCell>
                                                        {isFirstWeekOfMonth ? (
                                                            <Button 
                                                            variant="contained"
                                                            onClick={() => generatePDF(monthData.month, monthData.distance, monthData.cost)}
                                                            sx={{ 
                                                                backgroundColor: '#232F3E', 
                                                                color: '#FFFFFF', 
                                                                '&:hover': { backgroundColor: '#232F3E' },
                                                                textTransform: 'none',
                                                                borderRadius: '10px',
                                                                padding: '8px 16px',
                                                                fontSize: 'clamp(14px, 1.5vw, 14px)',
                                                                opacity: isCurrentMonth ? 0.7 : 1,
                                                            }}
                                                            disabled={isCurrentMonth}
                                                            title={isCurrentMonth ? "Invoice not available for current month" : `Download Invoice for ${monthData.month}`}
                                                        >
                                                            Download Invoice
                                                        </Button>
                                                        ) : ( 
                                                            <Typography variant="body2" sx={{ color: '#666666', textAlign: 'center', fontSize: 'clamp(12px, 1.5vw, 16px)' }}>
                                                                    Download available in the first week of every month.
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Collapse>
                    </Box>
                ))}
            </Collapse>
        </div>
    );
}

export default withAuthenticator(VehicleOdometerDetail, {
    theme,
});