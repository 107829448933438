import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
    Avatar, 
    Button, 
    Alert, 
    Typography,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Grid,
    Chip,
} from '@mui/material';
import { 
    Call as CallIcon, 
    Textsms as TextIcon, 
    CheckCircle as CheckInIcon, 
    RemoveCircle as CheckOutIcon, 
    Person as PersonIcon,
    ShareLocation as LocationIcon,
} from '@mui/icons-material';
import axios from 'axios';
import './QRScannerResultPage.css';

const QRScannerResultPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { scannedData } = location.state || {};
    const [student, setStudent] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [alertMessage, setAlertMessage] = useState(null);

    const fetchStudent = useCallback(async () => {
        try {
            const apiEndpoint = process.env.REACT_APP_API_DBRW_ENDPOINT;
            const response = await axios.get(`${apiEndpoint}/view-and-edit-student-data`);
            const studentData = response.data;
            const studentIndex = studentData.reduce((acc, student) => {
                acc[student.name.toLowerCase().trim()] = student;
                return acc;
            }, {});
            const foundStudent = studentIndex[scannedData.toLowerCase().trim()];
            if (foundStudent) {
                setStudent(foundStudent);
            } else {
                setAlertMessage({ type: 'warning', text: `No student found with name: ${scannedData}` });
            }
        } catch (error) {
            console.error('Error fetching student data:', error);
            setAlertMessage({ type: 'error', text: 'Failed to fetch student data. Please try again.' });
        }
    }, [scannedData]);

    useEffect(() => {
        if (scannedData) {
            fetchStudent();
        }
    }, [scannedData, fetchStudent]);

    const handleCheckIn = () => {
        setAlertMessage({ type: 'success', text: 'Student checked in successfully.' });
    };

    const handleCheckOut = () => {
        setAlertMessage({ type: 'success', text: 'Student checked out successfully.' });
    };

    const handleUpdateLocation = () => {
        setAlertMessage({ type: 'success', text: 'Location updated successfully.' });
    };

    const handleCallParent = (contact) => {
        if (contact) {
            window.location.href = `tel:${contact}`;
        } else {
            setAlertMessage({ type: 'info', text: 'No contact number available.' });
        }
    };

    const handleTextParent = (contact) => {
        if (contact) {
            window.location.href = `sms:${contact}`;
        } else {
            setAlertMessage({ type: 'info', text: 'No contact number available.' });
        }
    };

    if (!scannedData) {
        return <Typography className="error-message">No scanned data available.</Typography>;
    }

    return (
            <div className="scanner-result">
                <header className="scanner-result__header">
                <Button 
                    variant="contained"
                    onClick={() => navigate(-1)}
                    sx={{ 
                        backgroundColor: '#232F3E', 
                        color: '#FFFFFF', 
                        '&:hover': { backgroundColor: '#1D2731' },
                        fontSize: 'clamp(14px, 1.5vw, 14px)',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        textTransform: 'none',
                        margin: '20px'
                    }}
                >
                    Back
                </Button>
                    <div className="scanner-result__nav">
                        <Typography variant="h6" className="centered-text">Student Details</Typography>
                    </div>
                </header>
    
                <main className="scanner-result__content">
                    {student ? (
                        <>
                            <Card className="student-card">
                                <CardHeader
                                    avatar={
                                        <Avatar src={student.image_url} sx={{ width: 60, height: 60 }}>
                                            {!student.image_url && <PersonIcon />}
                                        </Avatar>
                                    }
                                    title={student.name}
                                    subheader={`Adm: ${student.adm_number}`}
                                    action={
                                        student?.payment_status && (
                                            <Chip 
                                                label={student.payment_status}
                                                color={student.payment_status === 'Paid' ? 'success' : 'warning'}
                                                size="small"
                                                sx={{ marginLeft: 'auto' }}
                                            />
                                        )
                                    }
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Pickup Time</Typography>
                                            <Typography variant="subtitle2">{student.morning_trip_status?.pickup_time || '__'}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Zone</Typography>
                                            <Typography variant="subtitle2">{student.zone || '__'}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Grade</Typography>
                                            <Typography variant="subtitle2">{student.grade || '__'}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Afternoon Vehicle</Typography>
                                            <Typography variant="subtitle2">{student.afternoon_vehicle || '__'}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Afternoon Dropoff Time</Typography>
                                            <Typography variant="subtitle2">{student.afternoon_trip_status?.dropoff_time || '__'}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Afternoon Dropoff Location</Typography>
                                            <Typography variant="subtitle2">{student.afternoon_trip_status?.place_address || '__'}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Morning Vehicle</Typography>
                                            <Typography variant="subtitle2">{student.morning_vehicle || '__'}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2">Morning Pickup Location</Typography>
                                            <Typography variant="subtitle2">{student.morning_trip_status?.place_address || '__'}</Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Button 
                                        variant="contained" 
                                        startIcon={<CheckInIcon />}
                                        onClick={handleCheckIn}
                                        className="action-btn"
                                        sx={{ 
                                            backgroundColor: '#232F3E', 
                                            color: '#FFFFFF', 
                                            '&:hover': { backgroundColor: '#1D2731' },
                                            textTransform: 'none',
                                            fontSize: 'clamp(14px, 1.5vw, 14px)',
                                            borderRadius: '10px',
                                            padding: '8px 16px',
                                        }} // Ensure sentence case
                                    >
                                        Check in
                                    </Button>
                                    <Button 
                                        variant="outlined"
                                        startIcon={<CheckOutIcon />}
                                        onClick={handleCheckOut}
                                        className="action-btn"
                                        sx={{ 
                                            backgroundColor: '#232F3E', 
                                            color: '#FFFFFF', 
                                            '&:hover': { backgroundColor: '#1D2731' },
                                            textTransform: 'none',
                                            fontSize: 'clamp(14px, 1.5vw, 14px)',
                                            borderRadius: '10px',
                                            padding: '8px 16px',
                                        }} // Ensure sentence case
                                    >
                                        Check out
                                    </Button>
                                </CardActions>
                            </Card>
    
                            <Card className="contact-card">
                                
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button 
                                                variant="contained" 
                                                startIcon={<CallIcon />}
                                                onClick={() => handleCallParent(student.parent_contact?.contact1)}
                                                className="action-btn"
                                                fullWidth
                                                size="small"
                                                sx={{ 
                                                    backgroundColor: '#232F3E', 
                                                    color: '#FFFFFF', 
                                                    '&:hover': { backgroundColor: '#1D2731' },
                                                    textTransform: 'none',
                                                    fontSize: 'clamp(14px, 1.5vw, 14px)',
                                                    borderRadius: '10px',
                                                    padding: '8px 16px',
                                                }} // Ensure sentence case
                                            >
                                                Call 1
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button 
                                                variant="outlined"
                                                startIcon={<TextIcon />}
                                                onClick={() => handleTextParent(student.parent_contact?.contact1)}
                                                className="action-btn"
                                                fullWidth
                                                size="small"
                                                sx={{ 
                                                    backgroundColor: '#232F3E', 
                                                    color: '#FFFFFF', 
                                                    '&:hover': { backgroundColor: '#1D2731' },
                                                    textTransform: 'none',
                                                    fontSize: 'clamp(14px, 1.5vw, 14px)',
                                                    borderRadius: '10px',
                                                    padding: '8px 16px',
                                                }} // Ensure sentence case
                                            >
                                                Text 1
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button 
                                                variant="contained" 
                                                startIcon={<CallIcon />}
                                                onClick={() => handleCallParent(student.parent_contact?.contact2)}
                                                className="action-btn"
                                                fullWidth
                                                size="small"
                                                sx={{ 
                                                    backgroundColor: '#232F3E', 
                                                    color: '#FFFFFF', 
                                                    '&:hover': { backgroundColor: '#1D2731' },
                                                    textTransform: 'none',
                                                    fontSize: 'clamp(14px, 1.5vw, 14px)',
                                                    borderRadius: '10px',
                                                    padding: '8px 16px',
                                                }} // Ensure sentence case
                                            >
                                                Call 2
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button 
                                                variant="outlined"
                                                startIcon={<TextIcon />}
                                                onClick={() => handleTextParent(student.parent_contact?.contact2)}
                                                className="action-btn"
                                                fullWidth
                                                size="small"
                                                sx={{ 
                                                    backgroundColor: '#232F3E', 
                                                    color: '#FFFFFF', 
                                                    '&:hover': { backgroundColor: '#1D2731' },
                                                    textTransform: 'none',
                                                    fontSize: 'clamp(14px, 1.5vw, 14px)',
                                                    borderRadius: '10px',
                                                    padding: '8px 16px',
                                                }} // Ensure sentence case
                                            >
                                                Text 2
                                            </Button>
                                    </Grid>
                                    <Button 
                                        variant="contained"
                                        startIcon={<LocationIcon />}
                                        color="primary"
                                        className="complete-btn"
                                        onClick={handleUpdateLocation}
                                        sx={{ 
                                            backgroundColor: '#232F3E', 
                                            color: '#FFFFFF', 
                                            '&:hover': { backgroundColor: '#1D2731' },
                                            textTransform: 'none',
                                            margin: '20px auto', // Adjust margin to bring the button higher
                                            fontSize: 'clamp(14px, 1.5vw, 14px)',
                                            borderRadius: '10px',
                                            padding: '8px 16px',
                                            
                                        }} // Ensure sentence case
                                    >
                                        Update Location
                                    </Button>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </>
                    ) : (
                        <Alert severity="warning">No student found with the scanned data.</Alert>
                    )}
                </main>
            </div>
        );
    };

    export default QRScannerResultPage;
