import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import QrScanner from 'qr-scanner';

const QRScannerPage = () => {
    const videoRef = useRef(null); // Ref for the video element
    const scannerRef = useRef(null); // Ref for the scanner instance
    const navigate = useNavigate();
    const [scannedResult, setScannedResult] = useState(''); // State to store scan results
    const [isSecureContext, setIsSecureContext] = useState(true); // State to check if the context is secure

    const handleCloseClick = useCallback(() => {
        if (scannerRef.current) {
            scannerRef.current.stop();
            scannerRef.current.destroy();
        }
        navigate('/nonAdminInterface');
    }, [navigate]);

    const handleScanSuccess = useCallback((data) => {
        console.log('Scan successful:', data);
        navigate('/qr-scanner-result', { state: { scannedData: data } }); // Navigate to QRScannerResultPage with scanned data
    }, [navigate]);

    useEffect(() => {
        if (window.isSecureContext) {
            const videoElement = videoRef.current;

            if (videoElement) {
                // Instantiate QrScanner and set up success/failure handlers
                const scanner = new QrScanner(
                    videoElement,
                    (result) => {
                        console.log('Scanned result:', result.data);
                        setScannedResult(result.data); // Store the scanned result
                        handleScanSuccess(result.data); // Handle successful scan
                    },
                    {
                        onDecodeError: (error) => {
                            console.warn('Scan failed:', error);
                        },
                        highlightScanRegion: true,
                        highlightCodeOutline: true,
                    }
                );

                scanner.start().catch((err) => {
                    console.error('Error starting QR scanner:', err);
                });

                // Save scanner instance to ref
                scannerRef.current = scanner;
            }
        } else {
            setIsSecureContext(false);
        }

        // Cleanup on unmount
        return () => {
            if (scannerRef.current) {
                scannerRef.current.stop();
                scannerRef.current.destroy();
            }
        };
    }, [handleScanSuccess]);

    return (
        <div className="qr-scanner-page" style={{ position: 'relative', textAlign: 'center' }}>
            {!isSecureContext && (
                <p style={{ color: 'red', marginTop: '20px', fontWeight: 'bold' }}>
                    The camera stream is only accessible if the page is transferred via HTTPS.
                </p>
            )}
            {isSecureContext && (
                <>
                    

                    {/* Video Element for QR Scanner */}
                    <video 
                        ref={videoRef} 
                        className="qr-scanner-video" 
                        style={{ width: '100%', maxWidth: '430px', height: 'auto', margin: '0 auto', border: '2px solid #ccc' }} 
                    />

                    {/* Display Scanned Result */}
                    {scannedResult && (
                        <p style={{ color: '#FF9900', marginTop: '20px', fontWeight: 'bold' }}>
                            Scanned Result: {scannedResult}
                        </p>
                    )}

                    <Button 
                        variant="contained"
                        onClick={handleCloseClick} 
                        sx={{ 
                            backgroundColor: '#FF9900', 
                            color: '#FFFFFF', 
                            '&:hover': { backgroundColor: '#1D2731' },
                            fontSize: 'clamp(14px, 1.5vw, 14px)',
                            borderRadius: '10px',
                            padding: '8px 16px',
                            textTransform: 'none',
                            margin: '20px',
                            position: 'relative',
                            zIndex: 2
                        }}
                    >
                        Close
                    </Button>
                </>
            )}
        </div>
    );
};

export default QRScannerPage;